
import React from 'react'
import styles from './css/test.module.css'
import Header from './Header'
import style from './css/ga.module.css'
import Header3 from './Header3'
function GA() {
  return (
    <div className={styles['body']}>
    <Header3/>
    <div className={style['ga-main']}>
      <h2>Role:</h2>
      <p>Research Assistant under Dr. Kanad Ghose</p>
        <h2>Tools: </h2>
        <p>Code Composer Studio, Sensor Controller Studio, TI- Packet Sniffer, TI- Btool, TI- Flash programmer,Android Studio etc.</p>
        <h2>Hardwares:</h2>
        <p>CC2640, CC2650, CC2640R2F, JTAG Debugger, BLE dongle, SaBLE-x-R2, SmartRF06EB, Oscilloscopes etc.</p>
        <h2>Language:  </h2>
        <p>Embedded C, Java, Python</p>
        <h2>Brief:</h2>
        <ul>
          <li>Engineering a wearable ECG sensor with energy-efficient over-the-air data transmission to maximize battery life</li>
          <li>Employed semaphores and events to achieve an optimum transmission frequency, ensuring data integrity without loss</li>
          <li>Created multiprocessing python application to plot graph in one process and append received data to file in another process</li>
          <li>Designed an android application to visualize ECG plots and it’s parameters while seamlessly connected over the air</li>
        </ul>

    </div>
    </div>
  )
}

export default GA
